import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducer';

import saga from './sagas';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        reducer,
        storeEnhancers(applyMiddleware(sagaMiddleware))
    );
    sagaMiddleware.run(saga);
    return store;
};

export default configureStore;
