import { createActions } from 'redux-actions';
import { createConstants } from 'utils';

export const constants = createConstants(
    'NEXT_PAGE',
    'NEXT_PERSON',
    'NEXT_NEWS',
    'UPDATING_BIRTHDAYS_SUCCESS',
    'UPDATING_NEWS_SUCCESS',
    'UPDATING_DATA_FAILED'
);

const actions = createActions({
    [constants.NEXT_PAGE]: () => ({}),
    [constants.NEXT_PERSON]: () => ({}),
    [constants.NEXT_NEWS]: () => ({}),
});

export default actions;
