import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';

import Logo from './components/logo';
import configureStore from 'core/store';
import Router from './core/router';
import * as serviceWorker from './serviceWorker';
import actions from './core/actions';

import './index.scss';

export const store = configureStore();

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.nextPage());
    }, [dispatch]);
    return (
        <div className="app-container">
            <Logo></Logo>
            <Router />
        </div>
    );
};

const AppContainer = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};

ReactDOM.render(<AppContainer />, document.getElementById('app'));
serviceWorker.unregister();
