import { isFunction, isObject, isArray } from 'lodash';

import config from 'core/config';

export const createConstants = (...constants) =>
    constants.reduce((res, constant) => {
        const result = res;
        result[constant] = constant;
        return result;
    }, {});

export const pickUniversalModelValues = (data, model) =>
    Object.entries(model).reduce((res, item) => {
        const result = res;
        const [modelKey, dataKey] = item;
        const dataField = data[modelKey];

        if (isArray(dataField)) {
            result[modelKey] = [];
            dataField.map((element) => {
                if (isArray(element)) {
                    return element.map((elem) => {
                        if (isObject(elem)) {
                            return result[modelKey].push(
                                pickUniversalModelValues(elem, model[modelKey])
                            );
                        }
                        return result[modelKey].push(data[dataKey]);
                    });
                }
                if (isObject(element)) {
                    return result[modelKey].push(
                        pickUniversalModelValues(element, model[modelKey])
                    );
                }

                return result[modelKey].push(element);
            });
        } else if (isFunction(dataKey)) {
            result[modelKey] = dataKey(data[modelKey]);
        } else if (isObject(dataField)) {
            if (Object.keys(dataField).length === 0) {
                result[modelKey] = null;
            } else {
                result[modelKey] = pickUniversalModelValues(
                    dataField,
                    model[modelKey]
                );
            }
        } else {
            result[modelKey] = data[dataKey];
        }
        return result;
    }, {});

export const pickArrayUniversalModelValues = (dataArray, model) =>
    dataArray.map((data) => pickUniversalModelValues(data, model));

export const timeout = function (timeout) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, timeout);
    });
};

export const getSrc = (data) => {
    if (data) {
        return `${config.baseUrl}${data}`;
    }

    return '';
};

export const getPhotoKey = (index) => {
    return `photo_${index}`;
};

export const getPhotoAlt = (index) => {
    return `Photo ${index + 1}`;
};

export const getPhotoType = (item) => {
    return item.typeImage || 'horizontal';
};
