import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import Spinner from 'components/spinner';

// static imports of pages
import history from './history';
import { paths } from './constants';

// dynamic imports of pages
const BirthdayGallery = lazy(() => import('pages/birthday-gallery'));
const News = lazy(() => import('pages/news'));

const { HOME, BIRTHDAY_GALLERY, NEWS } = paths;

const RouterContainer = () => (
    <Router history={history}>
        <Suspense fallback={<Spinner />}>
            <Switch>
                <Route
                    exact
                    path={HOME}
                    component={Spinner}
                />
                <Route
                    path={BIRTHDAY_GALLERY}
                    component={BirthdayGallery}
                />
                <Route
                    path={NEWS}
                    component={News}
                />
            </Switch>
        </Suspense>
    </Router>
);

export default RouterContainer;
