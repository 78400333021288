import { createSelector } from 'reselect';
import { SESSION_IMAGES_NUMBER } from './config/constants';

// persons
export const getAllPersons = (state) => state.persons;
const getPersonNumber = (state) => state.personNumber % state.persons.length;
const getIterationNumber = (state) =>
    Math.trunc(state.personNumber / state.persons.length);

const getPersonData = createSelector(
    getAllPersons,
    getPersonNumber,
    (data, personNumber) => data[personNumber]
);

export const getBirthdayPerson = createSelector(
    getPersonData,
    (data) => data && data.displayName
);

export const getBirthdayAvatar = createSelector(
    getPersonData,
    (data) => data && data.avatar
);

export const getImages = createSelector(
    getPersonData,
    getIterationNumber,
    (data, iterationNumber) => {
        const allPersonImages = (data && data.images) || [];

        if (allPersonImages.length < 3) {
            return [];
        }

        const startIndex = SESSION_IMAGES_NUMBER * iterationNumber;
        const endIndex = SESSION_IMAGES_NUMBER * (iterationNumber + 1);
        const images = [];
        for (let i = startIndex; i < endIndex; i++) {
            const imageIndex = i % allPersonImages.length;
            const image = allPersonImages[imageIndex];
            images.push(image);
        }
        return images;
    }
);

// news
export const getAllNews = (state) => state.news;
export const getNewsNumber = (state) => state.newsNumber % state.news.length;
export const getNews = createSelector(
    getAllNews,
    getNewsNumber,
    (data, newsNumber) => data[newsNumber]
);
