import request from 'utils/request';
import { HTTP_METHODS } from 'core/config/constants';
import config from 'core/config';

export default {
    fetchBirthdays: () =>
        config.mockData
            ? request(`/mock.json`, HTTP_METHODS.GET, {})
            : request(`/api/tv/birthday`, HTTP_METHODS.GET, {}),
    fetchNews: () =>
        // config.mockData
        //     ? request(`/events-mock.json`, HTTP_METHODS.GET, {})
        //     : request(`/api/tv/news`, HTTP_METHODS.GET, {}),
        // temporary for release 2
        request(`/events-mock.json`, HTTP_METHODS.GET, {}),
};
