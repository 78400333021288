import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import logo from 'assets/images/logo.svg';
import './index.scss';

const Logo = ({ small }) => {
    return (
        <img
            className={classNames('logo', small && 'small')}
            src={logo}
            alt="HiQo logo"
        />
    );
};

Logo.propTypes = {
    small: PropTypes.bool,
};
Logo.defaultProps = {
    small: false,
};

export default Logo;
