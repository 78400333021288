import { HTTP_METHODS } from 'core/config/constants';
import config from 'core/config';

const getHeaders = () => ({
    'Content-Type': 'application/json',
});

const hostWithPath = (path) =>
    config.mockData || path.includes('.json')
        ? `${config.appUrl}${path}`
        : `${config.baseUrl}${path}`;

const status = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    }

    return Promise.reject(new Error(response.statusText));
};

const json = (response) => {
    if (response.ok) {
        return response.json();
    }

    return false;
};

const request = (url, method, params) => {
    let fetchParams = {};
    const urlObject = new URL(hostWithPath(url));

    // replace with the next line for testing with mock
    // const urlObject = new URL('http://localhost:3000/mock.json');

    if (method === HTTP_METHODS.GET) {
        urlObject.search = new URLSearchParams(params);

        fetchParams = {
            method,
            headers: {
                ...getHeaders(),
            },
        };
    } else {
        fetchParams = {
            method,
            headers: {
                ...getHeaders(),
            },
            body: JSON.stringify(params),
        };
    }

    return fetch(urlObject, {
        ...fetchParams,
    })
        .then(status)
        .then(json)
        .catch((error) => {
            throw new Error(error);
        });
};

export default request;
