import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { constants } from './actions';

const initialState = {
    persons: [],
    news: [],
    error: null,
    personNumber: 0,
    newsNumber: 0,
};

export default handleActions(
    {
        [constants.NEXT_PERSON]: (state) =>
            update(state, {
                personNumber: { $set: state.personNumber + 1 },
            }),
        [constants.UPDATING_BIRTHDAYS_SUCCESS]: (state, { payload: data }) =>
            update(state, {
                persons: { $set: data },
                error: { $set: null },
                personNumber: { $set: 0 }, // reset person counter when we get new data
            }),
        [constants.NEXT_NEWS]: (state) =>
            update(state, {
                newsNumber: { $set: state.newsNumber + 1 },
            }),
        [constants.UPDATING_NEWS_SUCCESS]: (state, { payload: data }) =>
            update(state, {
                news: { $set: data },
                error: { $set: null },
                newsNumber: { $set: 0 },
            }),
        [constants.UPDATING_DATA_FAILED]: (state, { payload: error }) =>
            update(state, {
                error: { $set: error },
            }),
    },
    initialState
);
