export const HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
};

export const SESSION_IMAGES_NUMBER = 10;
export const GALLERY_SLIDE_TIMEOUT = 60 * 1000; // ms
export const NEWS_SLIDE_TIMEOUT = 30 * 1000; // ms
export const PHOTO_TIMEOUT = 4 * 1000; // ms
export const TEXT_GREETING_TIMEOUT = 20 * 1000; // ms
export const CONGRATULATION_TIMEOUT = 2 * 1000; // ms
